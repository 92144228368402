<template>
    <div class="wrapper" style="position:relative">
    
        <swiper ref="mySwiper1" :options="swiperOption" class="swiper">
          <swiper-slide  v-for="(item,index) in swiperPicList" :key="index">
              <a href="javascript:;">
                  <img class="img" :src="item" alt="商品图">
              </a>
          </swiper-slide>
        
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-scrollbar" style="border:0px solid red"></div>
      </swiper>
       
        <!--第二部分tab切换-->
        <!-- 第一部分 -->
        <div id="zone1" class="zone zone1">
            <div class="zoneTitle">
                <h2>货运服务</h2>
                <div class="littleLine"></div>
            </div>
            <div class="service">
                <!-- <div class="serviceItem"  >
                    <div class="serviceItemBg serviceItemBg1" @click="handleText" data-currentIndex="0"></div>
                    <h5 class="serviceItemText">合规咨询</h5>
                </div> -->
                <div class="serviceItem"  >
                    <div class="serviceItemBg serviceItemBg2"  @click="handleText"  data-currentIndex="1"></div>
                    <h5 class="serviceItemText">船货匹配</h5>
                </div>
                <div class="serviceItem"  >
                    <div class="serviceItemBg serviceItemBg3"  @click="handleText"  data-currentIndex="2"></div>
                    <h5 class="serviceItemText">货运保险</h5>
                </div>
                <div class="serviceItem"  >
                    <div class="serviceItemBg serviceItemBg5"   @click="handleText"  data-currentIndex="3"></div>
                    <h5 class="serviceItemText">轨迹查询</h5>
                </div>
                <div class="serviceItem"  >
                    <div class="serviceItemBg serviceItemBg4"  @click="handleText"  data-currentIndex="4"></div>
                    <h5 class="serviceItemText">专业团队</h5>
                </div>
            </div>
            <div class="serviceInfo serviceInfo1"  >
                {{serviceText[currentServiceIndex]}}
            </div>
           
        </div>
       
        <!-- 第二部分 -->
        <div id="zone2" class="zone zone2">
            <div class="zoneTitle">
                <h2>船民服务</h2>
                <div class="littleLine"></div>
            </div>
            <div class="content">
                <div class="contentItem">
                    <img src="../assets/newversion/icon6.png" alt="">
                    <div class="contentItemTitle">海量货源：</div>
                    <div class="contentItemInfo">千家货主海量一手货源，货主企业实名认证保障货源真实有效，一键对接快速找货。</div>
                </div>
                <div class="contentItem">
                    <img src="../assets/newversion/icon7.png" alt="">
                    <div class="contentItemTitle">加油服务：</div>
                    <div class="contentItemInfo">与多家品牌油站建立战略合作关系，为船舶加油提供快捷服务。价格更优服务更好，免除后顾之忧。</div>
                </div>
                <div class="contentItem">
                    <img src="../assets/newversion/icon8.png" alt="">
                    <div class="contentItemTitle">船舶维保：</div>
                    <div class="contentItemInfo">
                        平台整合了多种船 舶维保服务，保障船民在日常运输中的各类需求。</div>
                </div>
                <div class="contentItem">
                    <img src="../assets/newversion/icon9.png" alt="">
                    <div class="contentItemTitle">运费结算：</div>
                    <div class="contentItemInfo">平台与多家银行机构战略合作，为船民提供安全、及时的线上运费结算通道。</div>
                </div>
            </div>
        </div>
        <!-- 第三部分 -->
        <div id="zone3" class="zone zone3">
            <div class="zoneTitle">
                <h2>我们的优势</h2>
                <div class="littleLine"></div>
            </div>
            <div class="contentWrapper">
                <div class="imgBtn prev" id="prev" @click="imgShow(-1)">
                    <img src="../assets/newversion/prev.png" alt="">
                </div>
                       
               

                <div class="content" id="content">
                    <div class="longContent" id="longContent">
                        <div class="goodItem">
                            <img src="../assets/newversion/icon10.png" alt="">
                            <div class="title">系统平台</div>
                            <div class="info">拥有货主端、船东端及服务中台不同角色的服务终端。实现业务流程全程标准化、数字化、可视化，保障业务的合规性、可溯性、安全性。</div>
                        </div>
                        <div class="goodItem">
                            <img src="../assets/newversion/icon11.png" alt="">
                            <div class="title">行业经验</div>
                            <div class="info">好三由创始人出身于船民世家，深知航运业痛点，投身船运+互联网平台建设，经过多年技术和服务的积淀，“好三由智慧航运平台”具有领先的创新优势和前瞻的合规生态洞察。</div>
                        </div>
                        <div class="goodItem">
                            <img src="../assets/newversion/icon12.png" alt="">
                            <div class="title">业务资质</div>
                            <div class="info">国内首批获得“无船承运业务”资质企业。拥有网络货运平台含金量极高的网络平台货物运输代开增值税专用发票资质，实现一站式运输成本解决方案。</div>
                        </div>
                        <div class="goodItem">
                            <img src="../assets/newversion/icon13.png" alt="">
                            <div class="title">服务团队</div>
                            <div class="info">7*24小时专业强大的服务团队，有力支撑您业务开展中的各个环节，帮您查漏补缺，规避风险。</div>
                        </div>
                        <div class="goodItem">
                            <img src="../assets/newversion/icon14.png" alt="">
                            <div class="title">使用特权</div>
                            <div class="info">平台用户全部通过第三方权威名认证，诚信企业身份标识，船主信用分标识。所有合同均线上双方确认，合同经CFCA认证。</div>
                        </div>
                    </div>
                </div>
                <div class="imgBtn next" id="next" @click="imgShow(1)">
                    <img src="../assets/newversion/next.png" alt="">
                </div>
            </div>
        </div>

         <!-- 第四部分 -->
         <div id="zone4" class="zone zone4">
            <div class="zoneTitle">
                <h2>合作伙伴</h2>
                <div class="littleLine"></div>
            </div>
            <div class="brand">
                <ul>
                    <li>
                        <img src="../assets/newversion/partner_1.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_2.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_3.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_4.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_5.png" />
                    </li>
                 
                    <li>
                        <img src="../assets/newversion/partner_6.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_7.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_8.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_9.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_10.png" />
                    </li>
                 
                    <li>
                        <img src="../assets/newversion/partner_11.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_12.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_13.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_14.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_15.png" />
                    </li>
                
                    <li>
                        <img src="../assets/newversion/partner_16.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_17.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_18.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_19.png" />
                    </li>
                    <li>
                        <img src="../assets/newversion/partner_20.png" />
                    </li>
                </ul>
            </div>
        </div>
            
            <!-- 第五部分 -->
            <div class="indexSection4 zone zone5">
                <div class="zoneTitle">
                    <h2>平台数据</h2>
                    <div class="littleLine"></div>
                    
                </div>
                 <div class="numZone">
              <div class="numItem" v-for= "(item,index) in numList" :key="index">
                <h2>{{item.name}}</h2>
                 <animated-number
                  :value="item.value"
                  :formatValue="formatToPrice"
                  :duration="3000"
                  :key="index+item.value"
                  />
                <!--
                <p >{{item.value}}</p>
                -->
              </div>
            </div>
            </div>




    </div>
</template>
<script>
import {getNews,fetchNum} from "../request/api.js"
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "../../node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

import AnimatedNumber from "animated-number-vue";
export default {
  props: {   
  },
  components:{
    AnimatedNumber
  },
  data () {
    return {
      //swiperPicList:[require("../assets/swiper_pic1.jpg")],
      //swiperPicList:[require("../assets/banner1.jpg"),require("../assets/banner2.jpg")],
      swiperPicList:[require("../assets/banner2.jpg")],
      swiperOption:{
          loop:true, // 循环轮播
          autoplay:true, // 自动播放  也可设置自动轮播时间，比如：3000
          pagination:{ // 分页器
              el:".swiper-pagination"
          }
      },
      serviceText:[
        "专业的财税筹划服务体系，为客户提供高效、安全、符合国家相关法规的税务筹划方案，解决客户后顾之忧。",
        "海量数字船库共享船位、船期、船舶档案实时同步，多标签一键快速找船。",
        "携手国内各知名保险公司，无缝衔接各大保险网销渠道，货主企业可随时通过手机完成下单、支付、查询、理赔等全流程服务，让保险触手可及。",
        "与海事部门官方部门深度合作，实时监控船舶AIS船舶数据，运用物联网、云计算及人工智能等技术，实现对船舶多维度分析，提升船货运输效率。",
        "平台拥有专业的团队，根据货主的需求制定个性化的解决方案，能够为货主提供专业的咨询和技术支持，随时解答货主的疑问和处理问题"
      ],
      currentServiceIndex:0



    }
  },
  created () {
    this.getNews();
    this.getNum();
  },
  mounted () {
  },
  watch: {},
  computed: {
     swiper(){
            return this.$refs.mySwiper1.swiper
        }

  },
  methods: {
    handleTabCurrent(index){
      this.tabCurrent = index
    },
   getNews(){
     getNews().then(res=>{
       console.log("res",res)
       this.newsList = res.data.slice(0,3)
     })
   },
   getNum(){
     let arr = [];
     let obj = []
     fetchNum().then(res=>{
       console.log("res-num::",res)
       res.data.forEach(item => {
         if(item.pCode == "data_onboard_weight"){
           obj = {name:"装载总吨位",value:item.pValue}
         }
         if(item.pCode == "data_onboard_owner"){
            obj = {name:"注册货主数量",value:item.pValue}
         }
         if(item.pCode == "data_onboard_ships"){
            obj = {name:"注册船舶数量",value:item.pValue}
         }
          if(item.pCode == "data_onboard_store"){
           // obj = {name:"注册第三方物流公司数量",value:item.pValue}
           return
         }
         if(item.pCode == "data_onboard_orders"){
            obj = {name:"已完成订单数",value:item.pValue}
         }
         if(item.pCode == "data_onboard_ships_onload"){
            obj = {name:"在途船舶数",value:item.pValue}
         }
         if(item.pCode == "data_onboard_ships_unload"){
            obj = {name:"卸货中船舶数",value:item.pValue}
         }
         if(item.pCode == "data_onboard_amount_insurance"){
            obj = {name:"货运保险总金额",value:item.pValue}
            return;
         }
         arr.push(obj)
       })
       this.numList = arr
     })
   },
   moreNews(){
     this.$router.push("/news")
   },
   formatToPrice(value) {
      return `<p style="font-size:18px;font-weight:bold;color:#000">${value.toFixed(0)}</p>`;
    },
    goNewsDetail(e){
      let id = e.currentTarget.dataset.id;
      console.log("id:",id)
      this.$router.push({
       name:"NewsDetail",
        params:{id:id}
      })
    },
    makeCall(e){
      let tel = e.currentTarget.dataset.tel;
       window.location.href = 'tel:' + tel;
    },
    handleText(e){
      console.log(e)
      console.log(e.currentTarget)
      console.log(e.currentTarget.dataset)
      this.currentServiceIndex = Number(e.currentTarget.dataset.currentindex)
    },
     imgShow(flag) {
        var longContent = document.getElementById("longContent");
        //var content = document.getElementById("content");
        if (flag == 1) {
            console.log(longContent.scrollLeft);
            console.log(longContent.offsetWidth);
            console.log(longContent.offsetHeight);
            document.getElementById("content").scrollLeft += 145;

        } else {
            document.getElementById("content").scrollLeft -= 145;
        }
      }

  },
}
</script>
<style scoped>
v-deep #newBridge{
    display: inline-block;
}
*{margin:0;padding:0}
img{width:100%;display:block}
  .my-swipe .van-swipe-item {
    color: #fff;
    text-align: center;
    background-color: #39a9ed;
    height:260px
  }

.zone{}
.zone1{height:265px;width:375px;border:0px solid red;background: #fff;padding-top:30px}
.zoneTitle{text-align:center;}
.zoneTitle h2{font-size:14px}
.zoneTitle .littleLine{width:20px;height:2px;border-radius:2px;background:#7F7F7F;margin:0 auto;margin-top:5px}

.service{width:350px;display:flex;justify-content: space-between;margin:0 auto;border:0px solid red;margin-top:20px}
.serviceItem .serviceItemBg1{width:55px;height:55px;background:url(../assets/newversion/icon1.png);background-size:55px 55px}

.serviceItem .serviceItemText{font-size:12px;color:#7F7F7F;text-align:center;line-height:40px}
.serviceItem:hover .serviceItemBg1{width:55px;height:55px;background:url(../assets/newversion/icon1_current.png);cursor: pointer;background-size:55px 55px}
.serviceItem:hover .serviceItemText{color:#5E74FE}


.serviceItem .serviceItemBg2{width:55px;height:55px;background:url(../assets/newversion/icon2.png);background-size:55px 55px}
.serviceItem:hover .serviceItemBg2{width:55px;height:55px;background:url(../assets/newversion/icon2_current.png);cursor: pointer;background-size:55px 55px}

.serviceItem .serviceItemBg3{width:55px;height:55px;background:url(../assets/newversion/icon3.png);background-size:55px 55px}
.serviceItem:hover .serviceItemBg3{width:55px;height:55px;background:url(../assets/newversion/icon3_current.png);cursor: pointer;background-size:55px 55px}

.serviceItem .serviceItemBg4{width:55px;height:55px;background:url(../assets/newversion/team.png);background-size:55px 55px}
.serviceItem:hover .serviceItemBg4{width:55px;height:55px;background:url(../assets/newversion/team_current.png);cursor: pointer;background-size:55px 55px}

.serviceItem .serviceItemBg5{width:55px;height:55px;background:url(../assets/newversion/icon5.png);background-size:55px 55px}
.serviceItem:hover .serviceItemBg5{width:55px;height:55px;background:url(../assets/newversion/icon5_current.png);cursor: pointer;background-size:55px 55px}

.serviceInfo{font-size:13px;color:#7F7F7F;width:350px;margin:10px auto;text-align:center;line-height:150%;display:none}
.serviceInfo1{display: block;}

.zone2{height:410px;width:375px;border:0px solid red;background:#F1F5FF;overflow:hidden;padding-top:30px}
.zone2 .content{ width:370px}
.zone2 .content .contentItem{width:350px;height:60px;position:relative;margin:0 auto;margin-top:20px}
.zone2 .content .contentItem img{display:inline-block;width:46px;height:37px}
.contentItemTitle{border-bottom:1px solid #aaa;width:290px;height:24px;position:absolute;top:0;right:0;font-size:13px;line-height:26px}
.contentItemInfo{font-size:11px;width:290px;position:absolute;right:0px;top:25px;border:0px solid red;line-height: 170%;}

.zone3{height:450px;width:100%;border:0px solid red;padding-top:55px;background:#fff;overflow:hidden;position:relative}
.zone3 .contentWrapper{width:360px;margin:0 auto;border:0px solid blue;overflow:hidden;height:auto;position:relative;}
.zone3 .content{width:300px;height:auto;margin:0 auto;border:0px solid red;overflow-x:hidden;padding-top:20px;padding-bottom:20px;padding-left:10px}
.zone3 .content .longContent{width:845px;height:auto;margin:0 auto;border:0px solid red;}
.zone3 .content .longContent .goodItem{width:135px;height:auto; text-align:center;border-radius:8px;float:left;margin-right:10px;}
.zone3 .content .longContent .goodItem:hover{box-shadow: 0 0 20px #ccc;}


.imgBtn{position:absolute;width:30px;height:30px;border:0px solid red;cursor: pointer;}
.imgBtn.prev{left:0;top:160px;font-size:50px}
.imgBtn.next{right:0;top:160px;font-size:50px}
.imgBtn.prev img{float:left;width:25px }
.imgBtn.next img{float:right; width:27px}

.goodItem img{display:block;margin-top:20px;margin-left:18px;width:25px;height:27px}
.goodItem .title{font-size:14px;text-align:left;padding-left:14px;margin-top:10px;font-weight:bold}
.goodItem .info{font-size:12px;text-align:left;padding-left:14px;margin-top:10px;padding-right:14px;line-height:170%;text-justify: justify;}

.zone4{height:300px;width:100%;border:0px solid red;padding-top:15px;background:#fff;overflow:hidden;position:relative}

.brand{width:360px;margin:30px auto}
.brand ul{display:flex;flex-wrap: wrap;justify-content: space-between;}
.brand ul li{margin:0 10px 20px 0}
.brand ul li{width:22%}

.indexSection4.zone.zone5{background:#fff;height:200px;width:100%;border:0px solid red;background:url(../assets/newversion/data_bg.png);background-size:100% 100%;padding-bottom:20px}
.numZone{width:350px;margin:0 auto}
.numZone .numItem{width:33%;float:left;font-size:12px;text-align:center;margin-top:15px}
.numItem h2{height:30px;line-height:35px;}
.numItem p{font-size:14px;font-weight:bold;height:40px;line-height:40px;}



</style>
