<template>
  <div class="wrapper" data-info="产品中心">
      <!--第一部分大图片-->
      <div class="content1">
          <img src="../assets/images/banner.png"/>
      </div>
    
    <div class="bigTitle">
        好三由船东版APP
        <div class="bigTitleLine"></div>
    </div>

    <div class="zone zone1" style="background:#fff">
        <div class="img_wrapper">
            <img src="../assets/images/zone1.png" alt="">
        </div>
        <div class="text_wrapper text_wrapper1">
            <div class="title">
                海量真实货源
            </div>
            <div class="content ">
                <div class="content_item">
                  <span class="listFlag">■</span> 5000+合作货主，海量货运资源，
                </div>
                 <div class="content_item" style="padding-left:8px">
                   智能快捷货主匹配。
                </div>
                <div class="content_item">
                  <span class="listFlag">■</span> 第三方资金监管，保障运费安全。
                </div>
            </div>
        </div> 
    </div>
  
    <div class="zone zone2" style="background:#F1F5FF">
        
        <div class="text_wrapper text_wrapper2">
            <div class="title">
                航运加油服务
            </div>
            <div class="content ">
                <div class="content_item">
                  <span class="listFlag">■</span> 与多家品牌油站战略合作,为
                </div>
                <div class="content_item" style="padding-left:8px">
                        船民提供方便，快捷，优惠的
                </div>
                <div class="content_item" style="padding-left:8px">
                        加油服务。
                </div>
            </div>
        </div> 
        <div class="img_wrapper">
            <img src="../assets/images/zone2.png" alt="">
        </div>
    </div>
   
    <div class="zone zone3" style="background:#fff">
        <div class="img_wrapper">
            <img src="../assets/images/zone3.png" alt="">
        </div>
        <div class="text_wrapper text_wrapper1">
            <div class="title">
                实时港口信息
            </div>
            <div class="content ">
                <div class="content_item">
                  <span class="listFlag">■</span> 港口数据实时共享，可视化图表，
                </div>
                <div class="content_item" style="padding-left:8px">
                   一键展示船舶在港时长,船舶分类
                </div>
                <div class="content_item" style="padding-left:8px">
                     等。
                </div>
            </div>
        </div> 
    </div>

   
    <div class="zone zone2" style="background:#F1F5FF;padding-bottom:0;padding-top:49px">
        
        <div class="text_wrapper text_wrapper2">
            <div class="title" style="padding:0;padding-left:25px;margin-top:-20px">
                航运轨迹追踪
            </div>
            <div class="content">
              <span class="listFlag">■</span><div class="content_item" style="width:90%;padding-left:13px;position:relative;top:-20px;">
                    与海事部门深度合作，实时监控当前订单船泊所在的位置经纬度、装载状况、吃水、航速、航行时间、航行方向、出发港及出发时间、目的港及预抵时间等状况、为运输过程保驾护航。
                </div> 
            </div>
        </div> 
        <div class="img_wrapper" style="margin-top:10px">
            <img src="../assets/images/zone4.png" alt="">
        </div>
    </div>
 

  
    <div class="appDownload"  >
        
        <div class="appBox" >
            <h2>船主APP下载 </h2>
            <div class="code">
                <img src="../assets/product/android_ios.png" />
            </div>
        </div>
        <div class="appBox">
            <h2>船主小程序</h2>
            <div class="code">
                <img src="../assets/product/wechat_code_shipper.jpg" />
            </div>
        </div>
    </div>
   

    <div class="bigTitle">
        好三由货主端
        <div class="bigTitleLine"></div>
    </div>
    <div class="zone5">
        <img src="../assets/images/zone5.png" alt="" style="display:block;width:100%">
    </div>
    <div class="productPageSection2" style="background:none;height:auto;padding-bottom:40px">
     
       
        <div class="productText">
            <p>访问好三由官方网站-用户登录-选择货主登录或注册</p>
            <p>访问登录及注册地址：<a target="_blank" href="http://owner.hao3you.com">http://owner.hao3you.com</a></p>
            <p>微信小程序搜索：好三由货主</p>
        </div>
        <div class="appDownload2">
            <div class="appBox">
                <h2>货主小程序</h2>
                <div class="code">
                    <img src="../assets/product/wechat_code_owner.jpg" />
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
    }
  },
  created () {},
  mounted () {
  },
  watch: {},
  computed: {},
  methods: {},
  components: {},
}
</script>
<style scoped>
h2{margin:0;padding:0}
.content1 img{display:block;width:100%}
.title{width:100%;text-align:center;height:75px;border:0px solid red}
.title h2{font-weight:bold;font-size:20px;height:45px;margin:0;padding:0;line-height:60px}
.title p{color:#aaa;font-size:12px;height:30px;line-height:18px}

.content2Img{display:block;width:100%}
.codeZone{overflow:hidden;width:360px;margin:0 auto;margin-top:10px;}
.newAppImage{padding:0 40px}
.codeItem{float:left;width:120px;height:160px;}
.codeItem h2{text-align:center;font-size:12px;font-weight:bold}

.codeItem div img{width:105px;height:105px;padding:5px;display:block;margin:0 auto;margin-top:14px;box-shadow:0 0 10px #ccc}
.section2Bg{width:100%;display:block}

.codeZone2{width:120px;margin-top:10px}
.info{text-align:center;font-size:12px;line-height:120%;margin-top:20px;margin-bottom:10px;color:#555}

  .zone{width:375px;margin:0 auto;border:0px solid red;padding:35px 0;display:flex;justify-content: space-between;}
        .zone_wrapper.blue_bg{background:#F1F5FF}
        .zone .img_wrapper{height:auto;width:30%}
        .zone .img_wrapper img{display:block;height:85px;}
        .zone .text_wrapper{border:0px solid red;position:relative;width:70%;}
        .zone .text_wrapper .title{font-size:18px;font-weight:bold;height:18px;text-align:left;padding-left:25px}
        .zone .text_wrapper .content{font-size:14px;line-height:140%;padding:0 10px;margin-top:10px;padding-left:25px}
        .text_wrapper1 .title{ }
        
        .text_wrapper2 .title{ }
        .bigTitle{height:60px;font-size:20px;font-weight: bold;text-align:center;padding-top:20px;background:#fff;line-height:30px;border:0px solid red}
        .bigTitleLine{width:20px;height:3px;background:#999;margin:1px auto;border-radius:4px;}

        .appDownload{width:220px;margin:0 auto;border:0px solid red;display:flex;justify-content: space-between;padding:20px 0;text-align:center;font-size:14px;line-height:250%}
       
        .appDownload img{display:block;width:100px;padding:5px;background:#fff;border-radius:6px;box-shadow: 0 0 10px #ccc;}

        .appDownload2{width:110px;margin:0 auto;border:0px solid red;display:flex;justify-content: space-between;padding:20px 0;text-align:center;font-size:14px;line-height:250%}
       
        .appDownload2 img{display:block;width:100px;padding:5px;background:#fff;border-radius:6px;box-shadow: 0 0 10px #ccc;}


        .productText{font-size:14px;text-align: center;line-height:130%}
        .listFlag{position: relative;top:-2px;font-size:4px}


</style>